import React from "react"
import { Image } from "../VindiciaComponents"

const PartnerListGrid = ({ categories }) => {
  const categoriesA = categories.map(c => c.partner_category_item.document)
  return (
    <div className="uk-container">
      <ul data-uk-switcher="connect: #partner-cards" className="partners-cat-nav">
        {categoriesA.map((category, i) => (
          <li key={`catnav-${category.uid}`}>
            <div className="partners-cat-nav-item">
              <div>
                <Image
                  src={category.data.icon.fixed.src}
                  srcSet={category.data.icon.fixed.srcSet}
                />
                <a href="#partner-cards">{category.data.label}</a>
              </div>
            </div>
          </li>
        ))}
      </ul>
      <ul id="partner-cards" className="uk-switcher">
        {categoriesA.map((category, i) => (
          <li key={`cat-${category.uid}`}>
            <h2>{category.data.label}</h2>
            <div data-uk-slider="autoplay: true">
              <div className="uk-position-relative">
                <div className="uk-slider-container">
                  <ul className="uk-slider-items uk-grid-match uk-child-width-1-3@m uk-grid uk-grid-small">
                    {category.data.companies.map((company, j) => (
                      <li key={`card-${j}`}>
                        {company.partners && (
                          <div className="partner-card">
                            {company.partners.document.data.logo && (
                              <Image
                                src={company.partners.document.data.logo.fixed.src}
                                srcSet={company.partners.document.data.logo.fixed.srcSet}
                              />
                            )}
                            <div
                              className="description"
                              dangerouslySetInnerHTML={{
                                __html: company.partners.document.data.description.html,
                              }}
                            />
                            <a
                              href={company.partners.document.data.company_url.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="uk-button uk-border-pill"
                            >
                              Visit site
                            </a>
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
                <a
                  className="uk-position-center-left"
                  href="#"
                  data-uk-slidenav-previous
                  data-uk-slider-item="previous"
                ></a>
                <a
                  className="uk-position-center-right"
                  href="#"
                  data-uk-slidenav-next
                  data-uk-slider-item="next"
                ></a>
              </div>
              <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin-small"></ul>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
export default PartnerListGrid
