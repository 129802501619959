import React from "react"
import { Background } from "../VindiciaComponents"

const Hero = ({ data, ...props }) => {
  return (
    <header className="uk-position-relative uk-light">
      <div className="uk-light">
        <Background
          src={data.hero_banner_image.fluid.src}
          className="hero-bg"
          overlay="rgba(29, 38, 54, 0.43)"
        >
          <div className="hero-copy uk-container uk-flex-auto uk-position-relative uk-margin-medium-top">
            <div
              className="uk-width-3-5@s"
              data-uk-scrollspy="cls: uk-animation-slide-right-medium; target: > *; delay: 150"
            >
              <div dangerouslySetInnerHTML={{ __html: data.hero_title.html }} />
              {data.hero_subtitle && <h4 className="uk-margin-remove-top">{data.hero_subtitle}</h4>}
              {data.hero_button_link && (
                <a
                  href={data.hero_button_link}
                  className="uk-button uk-button-large uk-button-primary uk-border-pill"
                  data-uk-scrollspy-class="uk-animation-fade"
                >
                  {data.hero_button_label}
                </a>
              )}
            </div>
          </div>
        </Background>
        {data.body && props.path && (
          <div className="uk-visible@m localNavigation">
            <div>
              {data.body.map(
                (label, i) =>
                  label.primary &&
                  label.primary.section_name && (
                    <a
                      key={i}
                      className="uk-text-nowrap"
                      href={`#${label.primary.section_id}`}
                      data-uk-scroll="offset:100; duration: 800"
                    >
                      {label.primary.section_name}
                    </a>
                  ),
              )}
            </div>
          </div>
        )}
      </div>
    </header>
  )
}

export default Hero
