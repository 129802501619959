import React from "react"
import { Image } from "../VindiciaComponents"

const TwoColumnWithImage = ({ data }) => {
  const section = data.primary

  const theme = section.section_theme || "uk-light"

  const collapsible = label => {
    const collapse = {
      collapseStyle: label.includes("collapse")
        ? `uk-position-z-index uk-flex uk-flex-middle uk-padding-small background-pink uk-light`
        : null,
      position: label.includes("collapse") ? { marginLeft: "-200px" } : null,
    }
    return collapse
  }

  return (
    <section
      id={section.section_id}
      className={`uk-section two-column-with-image-section ${theme} ${section.muted_background &&
        "uk-background-muted"}`}
      style={{ backgroundColor: section.section_background_color || "#fff" }}
    >
      <div className={`uk-container two-column-with-image`}>
        <div className="uk-grid-large" data-uk-grid>
          <div className="uk-text-center uk-width-2-5@m">
            {section.image.fixed && (
              <Image
                className="uk-position-relative"
                style={data.slice_label && collapsible(data.slice_label).position}
                src={section.image.fixed.src}
                srcSet={section.image.fixed.srcSet}
                alt="Vindicia Retain"
                width={section.image.fixed.width}
                height={section.image.fixed.height}
              />
            )}
          </div>
          <div
            className={`uk-width-expand uk-flex uk-flex-middle ${data.slice_label &&
              data.slice_label.includes("image-right") &&
              "uk-flex-first@m"} ${data.slice_label &&
              collapsible(data.slice_label).collapseStyle}`}
          >
            <div>
              {section.copy && <div dangerouslySetInnerHTML={{ __html: section.copy.html }} />}
              {section.cta_url && section.cta_url.url && section.cta_text && (
                <a
                  className="uk-button uk-border-pill uk-background-primary"
                  href={section.cta_url.url}
                  target={section.cta_url.target}
                >
                  {section.cta_text}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TwoColumnWithImage
