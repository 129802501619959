import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../../components/layout"
import Header from "../../components/Partners/Hero"
import PartnerListGrid from "../../components/Partners/PartnerList"
import TwoColumnWithImage from "../../components/Content/TwoColumnWithImage"
import SEO, { seoPropsMapping } from "../../components/seo"
import { withPreview } from "gatsby-source-prismic"

const Partners = ({ data }) => {
  const section = data.prismicOurPartnersPage.data
  const categories = data.prismicOurPartnersPage.data.partner_category

  const contentNodes = section.body.map((section, i) => {
    switch (section.slice_type) {
      case "2-column":
        return (
          <div key={i}>
            <TwoColumnWithImage key={`section-${i}`} data={section} />
          </div>
        )
      case "embed_code":
        return (
          <section className="uk-section uk-container">
            <div key={section.primary.section_id}>
              <div dangerouslySetInnerHTML={{ __html: section.primary.embed_code }}></div>
            </div>
          </section>
        )
    }
  })

  const seoProps = seoPropsMapping(data.prismicOurPartnersPage.data)

  return (
    <Layout>
      <SEO {...seoProps} />
      <Header data={section} />
      {contentNodes}
      <div id="Partners" className="partners-container uk-container uk-text-center">
        <PartnerListGrid categories={categories} />
      </div>
      <section className="cta-blue uk-section uk-section-primary">
        <div className="uk-container uk-container-small uk-light uk-text-center">
          <h2 className="uk-text-center">Become a Vindicia Partner</h2>
          <p>Let’s start a friendship for the long-term.</p>
          <Link
            to={"/company/become-partner"}
            className="uk-button uk-button-large uk-button-primary uk-border-pill"
          >
            Become a Partner
          </Link>
        </div>
      </section>
    </Layout>
  )
}

export default withPreview(Partners)

export const query = graphql`
  {
    prismicOurPartnersPage {
      uid
      tags
      data {
        seo_title
        seo_description
        seo_keywords
        og_title
        og_description
        og_image {
          fixed(width: 1200) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        og_type

        twitter_site
        twitter_creator
        twitter_title
        twitter_description
        twitter_image_link {
          fixed(width: 4096) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        page_title
        hero_title {
          html
        }
        hero_subtitle
        hero_button_label
        hero_button_link
        hero_banner_image {
          fluid(maxWidth: 1400) {
            ...GatsbyPrismicImageFluid_noBase64
          }
        }
        body {
          ... on PrismicOurPartnersPageBody2Column {
            id
            slice_label
            slice_type
            primary {
              section_id
              section_name
              section_theme
              section_background_color
              copy {
                html
              }
              image {
                fixed(width: 500) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
            }
          }
          ... on PrismicOurPartnersPageBodyEmbedCode {
            id
            slice_label
            slice_type
            primary {
              section_id
              section_name
              embed_code
            }
          }
        }

        partner_category {
          partner_category_item {
            uid
            document {
              ... on PrismicPartnerCategories {
                id
                uid
                data {
                  label
                  icon {
                    fixed(width: 200) {
                      ...GatsbyPrismicImageFixed_noBase64
                    }
                  }
                  companies {
                    partners {
                      document {
                        ... on PrismicPartnerCompanies {
                          id
                          data {
                            company_name
                            company_url {
                              url
                              target
                            }
                            description {
                              html
                              text
                            }
                            logo {
                              fixed(width: 400) {
                                ...GatsbyPrismicImageFixed_noBase64
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
